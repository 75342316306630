import PostCard from '@/components/shared/Post/PostCard'
import { navigate } from 'gatsby'

interface EditorPickProps {
  title: string
  excerpt: string
  image: any
  alt: string
  slug: string
}

const EditorPick = ({ title, excerpt, image, slug }: EditorPickProps) => {
  function goToPost() {
    navigate(slug)
  }
  return (
    <>
      <PostCard title={title} image={image} excerpt={excerpt} slug={slug}>
        <>
          <PostCard.Image />
          <div className="px-4 py-6 md:p-6 space-y-6 border-r-[1px] border-b-[1px] border-black">
            <PostCard.Title heading="heading-3 md:heading-4 lg:heading-2" />
            <PostCard.Excerpt limitText={150} />
            <button className="dark desktop:hover:primary" onClick={goToPost}>
              Đọc ngay
            </button>
          </div>
        </>
      </PostCard>
    </>
  )
}

export default EditorPick
