import HealthStatusCarousel from '@/components/shared/HealthStatus/HealthStatusCarousel'

const HealthStatusSection = () => {
  return (
    <div className="base-container md:space-y-12">
      <div className="flex flex-col space-y-4 lg:space-y-8">
        <h1 className="section-heading">Tình trạng sức khỏe</h1>
        <p className="text-caption md:text-base">
          Hiểu thêm về tình trạng bệnh lý bạn đang gặp phải ngay hôm nay.
        </p>
      </div>
      <HealthStatusCarousel />
    </div>
  )
}

export default HealthStatusSection
