import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getCategoryTypeUrl } from '@/utils'
import loadable from '@loadable/component'

const ArrowRight = loadable(() => import('@vhl/icons'), {
  resolveComponent: component => component.ArrowRight,
})

interface CardProps {
  title: string
  href: string
  image: any
}

const Card = ({ title, href, image }: CardProps) => {
  return (
    <Link
      className="flex flex-col w-full h-full cursor-pointer group"
      to={`/${getCategoryTypeUrl('topic')}/${href}`}
    >
      <GatsbyImage
        image={image}
        alt={title}
        className="object-cover lg:h-full"
      />
      <div className="flex justify-between items-start py-3 lg:px-2 lg:py-4">
        <h3 className="duration-300 heading-5 lg:heading-6 xl:heading-5 group-hover:text-blue-500">
          {title}
        </h3>
        <ArrowRight className="w-8 h-8 transition duration-300 transform lg:w-6 lg:h-6 xl:w-8 xl:h-8 stroke-black group-hover:stroke-blue-500 group-hover:translate-x-1" />
      </div>
    </Link>
  )
}

export default Card
