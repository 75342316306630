import type { SidebarType } from '@/types/components'

import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import EditorPick from './EditorPick'
import { useEditorPickPost } from '@/hooks/useEditorPickPost'
import { scrollTo } from '@/utils'
import loadable from '@loadable/component'

const VerticalSidebar = loadable(() => import('./VerticalSidebar'))
const ToggleSidebar = loadable(() => import('./ToggleSidebar'))
const ArrowDown = loadable(() => import('./ArrowDown'))

const HeroSection = () => {
  const [sidebarType, setSidebarType] = useState<SidebarType>(null)
  const editorPickPost = useEditorPickPost()
  return (
    <div className="flex" id="hero-section">
      <div className="w-full">
        <EditorPick
          title={editorPickPost.title}
          excerpt={editorPickPost.excerpt}
          slug={editorPickPost.slug}
          alt="Hero Image"
          image={editorPickPost.featuredImage}
        />
      </div>
      <div className="relative tablet:flex flex-col w-full overflow-hidden border-b-[1px] hidden">
        <VerticalSidebar setSidebarType={setSidebarType} />
        <ArrowDown onClick={() => scrollTo('footer', -80)} />
        <AnimatePresence exitBeforeEnter>
          {sidebarType && (
            <motion.div
              key={sidebarType}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }}
              initial={{ left: '100%' }}
              animate={{ left: 0 }}
              exit={{ left: '100%' }}
              transition={{ duration: 0.8 }}
            >
              <ToggleSidebar
                type={sidebarType}
                onOutsideClick={() => setSidebarType(null)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default HeroSection
